.reuseModalOverlay {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  position: fixed;
}

.reuseModalParentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
}

.reuseModalHolder {
  width: 100%;
  height: 100%;
  display: flex;
  border: solid 1px #ddd;
  background: #f0f0f0;
  padding: 30px;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 99999;
}

.innerRndComponent {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
}

.reuseModalCloseBtn {
  position: fixed;
  top: 10px;
  right: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #ffffff;
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  z-index: 9999999;
}
